<template>
  <div class="support">
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="4">
          <div class="support-action" v-if="!showdetail">
            <div class="card2">
              <h4>{{ $t('Support.221') }}</h4>
              <div class="form-group">
                <label for="">{{ $t('Support.220') }}</label>
                <b-form-select class="form-control" v-model="ticket.subject">
                  <option
                    :value="list.ticket_subject_id"
                    v-for="list in ListSubject"
                    :key="list.id"
                  >
                    {{ list.ticket_subject_name.split(' ').join('_') }}
                  </option>
                </b-form-select>
              </div>
              <div class="form-group">
                <label for="">{{ $t('Support.222') }} (*)</label>
                <textarea
                  rows="5"
                  class="form-control"
                  v-model="ticket.content"
                ></textarea>
              </div>

              <div class="form-group text-center">
                <button @click="sendTicket">
                  {{ $t('Support.223') }}
                </button>
              </div>
            </div>
          </div>
          <div class="support-action" v-else>
            <!-- <div class="card2"> -->
              <DetailTicket
                :ID="ticketDetails.id"
                :Subject="ticketDetails.subject"
                :SubjectID="ticketDetails.SubjectID"
              />
            <!-- </div> -->
          </div>
        </b-col>
        <b-col cols="12" lg="8">
          <div class="support-table">
            <div class="card2">
              <div class="table-responsive">
                <table
                  class="table table-vcenter table-hover table-striped table-condensed"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>{{ $t('Support.220') }}</th>
                      <th>{{ $t('Support.224') }}</th>
                      <th>{{ $t('MainContent.122') }}</th>
                      <th>{{ $t('Win_Loss.196') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list in ListTicket" :key="list.ticket_Time">
                      <td>{{ list.ticket_ID }}</td>
                      <td>{{ list.ticket_subject_name }}</td>
                      <td>
                        <span
                          :style="
                            list.ticket_Status == 'Waiting'
                              ? 'padding:5px 10px;font-size:14px;background: yellow;color:#000'
                              : 'padding:5px 10px;font-size:14px;background: green'
                          "
                          >{{ list.ticket_Status }}</span
                        >
                      </td>
                      <td>{{ list.ticket_Time }}</td>
                      <td>
                        <a
                          class="btn btn-success btn-sm"
                          @click="
                            showDetail(
                              list.ticket_ID,
                              list.ticket_subject_name,
                              list.ticket_subject_id
                            )
                          "
                          >{{ $t('Support.222') }}</a
                        >
                      </td>
                    </tr>
                    <tr v-if="!ListTicket">
                      <td colspan="6" class="text-center">
                        {{ $t('Support.225') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DetailTicket from './DetailTicket.vue';
import { mapMutations, mapGetters } from 'vuex';
export default {
  components: { DetailTicket },
  data() {
    return {
      ticket: {
        content: '',
        subject: 1,
        token: '',
      },
      ticketDetails: {
        id: null,
        subject: null,
        SubjectID: null,
      },
      showdetail: false,
    };
  },
  computed: {
    ...mapGetters({
      ListSubject: 'ticket/ListSubject',
      ListTicket: 'ticket/ListTicket',
    }),
  },
  methods: {
    sendTicket: async function() {
      this.$store.dispatch('ticket/req_postSubject', this.ticket);
      this.ticket.content = '';
    },
    showDetail(id, subject, SubjectID) {
      this.ticketDetails.id = id;
      this.ticketDetails.subject = subject;
      this.ticketDetails.SubjectID = SubjectID;
      if (id === undefined || id === null) {
        this.$toastr.e(
           this.$t('Message.257'),
           this.$t('Message.258')
        );
        return;
      } else {
        this.showdetail = false;
        this.$nextTick(() => {
          this.showdetail = true;
        });
      }
    },
  },
  created() {
    this.$store.dispatch('ticket/req_getTicket');
    let self = this;
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'HiddenChat':
          self.ticketDetails = {
            id: null,
            subject: null,
            SubjectID: null,
          };
          self.showdetail = false;
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
.support {
  padding: 60px 20px 60px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  .support-action {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .card2 {
      width: 100%;
      background-color: #2d3035;
      padding: 20px;
      border-radius: 10px;
      color: rgba(153, 164, 176, 0.8);
      @media (max-width: 767px) {
        width: 80%;
      }
      @media (max-width: 575px) {
        width: 100%;
      }
      .form-control {
        border: 1px solid #2d3035;
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: #d4fa4d;
        option {
          background-color: rgb(41, 40, 40);
          border-radius: 15px;
          border: 0px;
        }
      }
      .custom-select {
        border: 1px solid #2d3035;
        background-color: rgba(84, 86, 88, 0.452);
        border-radius: 15px;
      }
      button {
        height: 50px;
        width: 7.25rem;
        color: #f5f6f7;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #5da000;
        background-image: conic-gradient(from 1turn, #458802, #7bc514);
        border-radius: 6.25rem;
        font-weight: bold;
        border: none;
      }
    }
  }
  .support-table {
    width: 100%;
    margin-bottom: 10px;
    table {
      color: white;
      position: relative;
      border-radius: 10px;
      thead {
        border-radius: 10px;
        tr {
          border-radius: 10px;
          th {
            border: none;
            position: sticky;
            top: 0;
            z-index: 10;
            background: #5da000;
          }
        }
      }
      tbody {
        tr {
          border: none;
          &:nth-child(odd) {
            color: rgb(185, 181, 181);
            background-color: rgba(90, 84, 84, 0.288);
          }
          &:nth-child(even) {
            color: rgb(223, 223, 226);
          }
          td {
            border: none;
          }
        }
      }
    }
    .table-responsive {
      height: auto;
      max-height: 600px;
      overflow-y: auto;
      border-radius: 10px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      // &::-webkit-scrollbar-thumb {
      //   background: rgb(60, 255, 0);
      //   border-radius: 10px;
      // }
    }
    .table-hover tbody tr:hover {
      color: rgb(59, 57, 57);
      background-color: rgba(255, 252, 252, 0.89);
    }
  }
}
</style>
